const dataFaq = [
  {
    id: 1,
    title: "What are the NFTs?",
    content:
      "NFTs are individual tokens with valuable information stored in them. Because they hold a value primarily set by the market and demand, they can be bought and sold just like other physical types of art. NFTs' unique data makes it easy to verify and validate their ownership and the transfer of tokens between owners.",
    show: "show",
  },
  {
    id: 2,
    title: "How do i get NFTs?",
    content:
      "It’s an easy process to buy an NFT once you’ve funded your account. Most marketplaces are in an auction format, so you’ll need to submit a bid for the NFT you want to purchase. Some marketplaces operate more like an exchange, using highest bid and lowest ask for NFTs that have several prints. A benefit of purchasing an NFT from the primary marketplace is the potential resale value directly after the product goes on sale. Some NFTs that are in high demand will sell for 5 to 10 times their initial price right after the release. The downside to buying NFTs on the primary marketplace is it’s hard to estimate the demand for the art. On the secondary marketplace, you can compare your purchase to previous sales. ",
  },
  {
    id: 3,
    title: "How can we buy your NFTs?",
    content: [
      "1. Purchase EthereumSince most NFTs are Ethereum-based tokens, most marketplaces for these collectibles accept only Eth tokens as payment. If you already have an account with a cryptocurrency exchange, you can purchase Ethereum on it and send your crypto to your MetaMask wallet.If you don’t already have a crypto exchange account, Coinbase and eToro are good options for beginners.For more click here",
      <a href="https://www.coinbase.com/how-to-buy/ethereum">(link)</a>,
      " to learn buying etherium",
      <br />,
      "2. Convert Etherium to MaticOur NFT collection can be bought through polygon network, to reduce fees we use matic WETH coin.Its simple to convert Etherium to Matic.To convert etherium to matic watch this article here. ",
      <a href="https://docs.origami.farm/guides/how-to-transfer-fund-from-eth-to-matic">
        (link)
      </a>,
      <br />,
      "3. Connect your MetaMask to OpenSeaThere are many marketplaces to buy and sell NFTs.We chosed Opensea as the best.After you connect your wallet with metamask, you’ll be able to purchase different types of art or collectibles. 4. Buy Your NFT It’s an easy process to buy an NFT once you’ve funded your account. After you connected your opensea with your wallet.Click the link of our collection in top of our website. Choose your favorite item and click buy now. A benefit of purchasing an NFT from the primary marketplace is the potential resale value directly after the product goes on sale. Some NFTs that are in high demand will sell for 5 to 10 times their initial price right after the release. The downside to buying NFTs on the primary marketplace is it’s hard to estimate the demand for the art.On the secondary marketplace, you can compare your purchase to previous sales.",
    ],
  },
  {
    id: 4,
    title: "Who are the team behind the project?",
    content:
      "The NizaGlobal team will bring this project to life.But after a few months we will be bringing on a CEO just for the NizaShark brand.We will instruct the CEO that their goal is to make the biggest NFT brand in the world… NizaShark will be competing with our own project. Should be fun.",
  },
  // {
  //   id: 5,
  //   title: "How can we buy your NFTs?",
  //   content:
  //     "Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.",
  // },
  // {
  //   id: 6,
  //   title: "Who are the team behind the project?",
  //   content:
  //     "Urna vitae erat et lacus, consectetur ac nulla vestibulum lobortis. Nulla dapibus urna volutpat venenatis, risus faucibus.",
  // },
];

export default dataFaq;
