const dataAbout = [
  {
    id: 1,
    title: "Unique, 1/1 generated",
    desc: "",
    class: "active",
  },
  {
    id: 2,
    title: "10,000 NFTs",
    desc: "",
  },
  {
    id: 3,
    title: "With Us Success is Guaranteed",
    desc: "",
  },
];

export default dataAbout;
