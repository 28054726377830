import bgImg1 from "../images/background/HeaderBG.png";
import bgImg2 from "../images/background/bg-3.jpg";
import bgImg3 from "../images/background/bg-4.jpg";
import img1 from "../images/background/bck2.jpg";

const dataSlider = [
  {
    id: 1,
    title: "NIZA SHARK NFT",
    desc: "",
    bgImg: img1,
    img: bgImg1,
  },
  //   {
  //     id: 2,
  //     title: "CYbox nft collectionS for everyone",
  //     desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //     bgImg: bgImg2,
  //     classAction: "two",
  //   },
  //   {
  //     id: 3,
  //     title: "CYbox nft collectionS for everyone",
  //     desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //     bgImg: bgImg3,
  //     classAction: "three",
  //   },
  //   {
  //     id: 4,
  //     title: "CYbox nft collectionS for everyone",
  //     desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //     bgImg: bgImg1,
  //     img: img1,
  //   },
  //   {
  //     id: 5,
  //     title: "CYbox nft collectionS for everyone",
  //     desc: "Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.",
  //     bgImg: bgImg2,
  //     classAction: "two",
  //   },
];

export default dataSlider;
