import img1 from "../images/common/team6.png";
import img2 from "../images/common/team7.png";
import img3 from "../images/common/team8.png";
import img4 from "../images/common/team9.png";
import img5 from "../images/common/team10.png";
import img6 from "../images/common/team11.png";

const dataTeam = [
  {
    id: 1,
    img: img1,
    name: "Niza-01",
    position: "Conceptor & Artist・Creator of NizaShark",
    listsocial: [],
  },
  {
    id: 2,
    img: img2,
    name: "NY",
    position: "Digital Technologist・Co-conceptor",
    listsocial: [
      //   {
      //     id: 1,
      //     icon: "fab fa-twitter",
      //     link: "#",
      //   },
      //   {
      //     id: 2,
      //     icon: "fab fa-facebook-f",
      //     link: "#",
      //   },
      //   {
      //     id: 3,
      //     icon: "fa fa-paper-plane",
      //     link: "#",
      //   },
      //   {
      //     id: 4,
      //     icon: "fab fa-reddit",
      //     link: "#",
      //   },
    ],
  },
  {
    id: 3,
    img: img3,
    name: "SD",
    position: "Strategic Partner from Nizaex",
    listsocial: [],
  },
  {
    id: 4,
    img: img4,
    name: "MC",
    position: "Content Writer",
    listsocial: [],
  },
  {
    id: 5,
    img: img5,
    name: "SPARKO",
    position: "Marketing & Community Manager",
    listsocial: [],
  },
];

export default dataTeam;
