import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const { data } = props;

  const [dataBlock] = useState({
    title: "WELCOME TO THE SQUAD",
    desc: "NIZA SHARK is a collection of 10000 shark NFTs.",
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                <h4 className="title">{dataBlock.title}</h4>
              </div>
              <p>{dataBlock.desc}</p>
              <a
                href="https://opensea.io/collection/niza-shark"
                target="_blank"
                className="tf-button btn-effect"
              >
                <span className="boder-fade"></span>
                <span className="effect">get Nfts</span>
              </a>
              {/* <Button
                title="get Nfts"
                path="https://opensea.io/collection/niza-shark"
              /> */}
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {data.map((item) => (
                <AboutItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
