const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'About',
        links: 'about-v2',
    },
    {
        id: 3,
        name: 'Road Map',
        links: 'road-map-v2',

    },
    {
        id: 4,
        name: 'Nft',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'Nft Item',
                links: '/nft-item'
            },
            {
                id: 2,
                sub: 'Nft Item Details',
                links: '/nft-item-details'
            },
        ],
    },
    {
        id: 5,
        name: 'Blog',
        links: 'blog',
    },
    {
        id: 6,
        name: 'Contact',
        links: '/contact'
    },
    
]

export default menus;