const dataRoadmap = [
  {
    id: 1,
    time: "SALE STARTS",
    list: [
      {
        text: " April 1, 2022",
      },
      {
        text: "Collection of 10,000 NFT",
      },
      {
        text: "Different Shapes",
      },
      {
        text: "With different clothes, eye, mouth, and hat",
      },
    ],
    positon: "left",
  },
  {
    id: 2,
    time: "Q2 2022",
    list: [
      {
        text: "Almost 20% of Collection has hand in shark's mouth",
      },
      {
        text: "Different Weapons",
      },
      {
        text: "Different Hat, almost 20% of collection shark has hat",
      },
      {
        text: "Different shapes of head and eye",
      },
    ],
    positon: "left",
    style: "normal",
  },
  {
    id: 3,
    time: "Q1 2022",
    list: [
      {
        text: "Different Backgrounds",
      },
      {
        text: "Different clothes",
      },
      {
        text: "10% of collection has king clothes",
      },
    ],
    positon: "right",
    style: "normal",
  },
  //   {
  //     id: 4,
  //     time: "Q3 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "right",
  //   },
  //   {
  //     id: 5,
  //     time: "Q2 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "left",
  //   },
  //   {
  //     id: 6,
  //     time: "Q1 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "right",
  //   },
  //   {
  //     id: 7,
  //     time: "Q4 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "left",
  //   },
  //   {
  //     id: 8,
  //     time: "Q3 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "right",
  //   },
  //   {
  //     id: 9,
  //     time: "Q2 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "left",
  //   },
  //   {
  //     id: 10,
  //     time: "Q1 2022",
  //     list: [
  //       {
  //         text: "Aenean tempor",
  //       },
  //       {
  //         text: "Fusce arcu maecenas",
  //       },
  //       {
  //         text: "Malesuada felis sem elementum",
  //       },
  //       {
  //         text: "Risus eu metus",
  //       },
  //     ],
  //     positon: "right",
  //   },
];

export default dataRoadmap;
